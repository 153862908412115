<template>
    <div class="card" v-if="loading">
        <div class="card-body d-flex flex-column p-9">
            <el-skeleton />
        </div>
    </div>
    <div class="user-statistic w-100" v-else>
        <div class="user-statistic-box" v-for="(user, userIndex) in userStatistics" :key="userIndex" :class="userIndex != 0 && 'mt-7'">
            <div class="user-statistic-info" v-if="showUserTitle">
                <div class="d-flex align-items-center cursor-pointer" @click="showUser(user.id)">
                    <span class="expandColumn cursor-pointer collapsible rotate" v-bind:class="{collapsed: showUserID != user.id}">
                        <div class="me-3 rotate-90">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/arrows/arr071.svg" />
                            </span>
                        </div>
                    </span>
                    <h2 class="mb-0">{{ sprintf('%s#%d', [user.full_name, user.id]) }}</h2>
                </div>
                <div class="separator separator-dashed mt-3 mb-7 border-gray-400"></div>
            </div>
            <template v-if="showUserID == user.id">
                <div class="user-statistic-detail" v-if="user.is_statistic_available">
                    <div class="row g-5 g-xl-8 mb-5 mb-xl-1" v-if="user.statistic.total_watch_lesson || user.statistic.total_paragraph_statistic || user.statistic.total_exam">
                        <div class="col-xl-4" v-if="user.statistic.total_watch_lesson">
                            <div class="card-xl-stretch mb-xl-8 bg-danger card">
                                <div class="card-header pt-5 border-bottom-0">
                                    <div class="card-title d-flex flex-column">
                                        <span class="fs-2hx fw-bold text-white me-2 lh-1 ls-n2">{{ user.statistic.total_watch_lesson.total_count }}</span>
                                        <span class="text-white  pt-1 fw-semibold fs-6">{{ $t('pages.statistic.student.totalWatchLesson.title') }}</span>
                                    </div>
                                </div>
                                <div class="card-body d-flex align-items-end pt-0">
                                    <div class="d-flex align-items-center flex-column mt-3 w-100">
                                        <div class="d-flex justify-content-between fw-bold fs-8 text-white w-100 mt-auto mb-2">
                                            <span>{{ sprintf('%s: %s/%s', [$t('pages.statistic.student.totalWatchLesson.cols.totalWatchingDuration'), user.statistic.total_watch_lesson.total_watching_duration, user.statistic.total_watch_lesson.total_duration ])}}</span>
                                            <span>{{ user.statistic.total_watch_lesson.total_watching_percentage }}%</span>
                                        </div>
                                        <div class="h-8px mx-3 w-100 bg-white bg-opacity-50 rounded">
                                            <div class="bg-white rounded h-8px" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" :style="sprintf('width: %d%;', [user.statistic.total_watch_lesson.total_watching_percentage])"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4" v-if="user.statistic.total_paragraph_statistic">
                            <div class="card-xl-stretch mb-xl-8 bg-primary card">
                                <div class="card-body">
                                    <span class="svg-icon svg-icon-white svg-icon-3x">
                                        <inline-svg src="/media/icons/duotune/graphs/gra001.svg"/>
                                    </span>
                                    <div class="text-inverse-danger fw-bold fs-2 mb-2 mt-5">{{ $t('pages.statistic.student.totalParagraph.title') }}</div>
                                    <div class="row fs-6">
                                        <div class="col-sm-4 mb-1 mb-sm-0">
                                            <div class="text-inverse-danger fw-bold">{{ $t('pages.statistic.student.totalParagraph.cols.total') }}</div>
                                            <div class="text-inverse-danger">{{ user.statistic.total_paragraph_statistic.total }}</div>
                                        </div>
                                        <div class="col-sm-4 mb-1 mb-sm-0 text-start text-sm-center">
                                            <div class="text-inverse-danger fw-bold">{{ $t('pages.statistic.student.totalParagraph.cols.uniqueTotal') }}</div>
                                            <div class="text-inverse-danger">{{ user.statistic.total_paragraph_statistic.unique_total }}</div>
                                        </div>
                                        <div class="col-sm-4 text-start text-sm-end">
                                            <el-tooltip :content="$t('pages.statistic.student.totalParagraph.tooltip.totalSpentTime')" effect="light">
                                                <div>
                                                    <div class="text-inverse-danger fw-bold">{{ $t('pages.statistic.student.totalParagraph.cols.totalSpentTime') }}</div>
                                                    <div class="text-inverse-danger">{{ user.statistic.total_paragraph_statistic.total_spent_time }}</div>
                                                </div>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4" v-if="user.statistic.total_exam">
                            <div class="card-xl-stretch mb-xl-8 bg-info card">
                                <div class="card-body">
                                    <span class="svg-icon svg-icon-white svg-icon-3x">
                                        <inline-svg src="/media/icons/duotune/graphs/gra001.svg"/>
                                    </span>
                                    <div class="text-inverse-danger fw-bold fs-2 mb-2 mt-5">{{ $t('pages.statistic.student.totalExam.title') }}</div>
                                    <div class="row fs-6">
                                        <div class="col-sm-4 mb-1 mb-sm-0">
                                            <div class="text-inverse-danger fw-bold">{{ $t('pages.statistic.student.totalExam.cols.total') }}</div>
                                            <div class="text-inverse-danger">{{ user.statistic.total_exam.total }}</div>
                                        </div>
                                        <div class="col-sm-4 mb-1 mb-sm-0 text-start text-sm-center">
                                            <div class="text-inverse-danger fw-bold">{{ $t('pages.statistic.student.totalExam.cols.uniqueTotal') }}</div>
                                            <div class="text-inverse-danger">{{ user.statistic.total_exam.unique_total }}</div>
                                        </div>
                                        <div class="col-sm-4 text-start text-sm-end">
                                            <div class="text-inverse-danger fw-bold">{{ $t('pages.statistic.student.totalExam.cols.averageGrade') }}</div>
                                            <div class="text-inverse-danger">{{ user.statistic.total_exam.average_grade }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row g-5 g-xl-8 mb-5 mb-xl-1" v-if="user.statistic.total_order">
                        <div class="col-xl-6">
                            <div class="card-xl-stretch mb-xl-8 bg-success card">
                                <div class="card-body">
                                    <span class="svg-icon svg-icon-white svg-icon-3x">
                                        <inline-svg src="/media/icons/duotune/graphs/gra001.svg"/>
                                    </span>
                                    <div class="text-inverse-danger fw-bold fs-2 mb-2 mt-5">{{ $t('pages.statistic.student.totalOrder.success.title')}}</div>
                                    <div class="row fs-6">
                                        <div class="col-xl-6 d-flex align-items-center">
                                            <div class="text-inverse-danger fw-bold">{{ $t('pages.statistic.student.totalOrder.cols.count') }}:</div>
                                            <div class="text-inverse-danger">{{ user.statistic.total_order.success_count }}</div>
                                        </div>
                                        <div class="col-xl-6 d-flex align-items-center">
                                            <div class="text-inverse-danger fw-bold">{{ $t('pages.statistic.student.totalOrder.cols.price') }}:</div>
                                            <div class="text-inverse-danger">{{ user.statistic.total_order.success + ' ' + $root.defaultCurrency.suffix}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6">
                            <div class="card-xl-stretch mb-xl-8 bg-danger card">
                                <div class="card-body">
                                    <span class="svg-icon svg-icon-white svg-icon-3x">
                                        <inline-svg src="/media/icons/duotune/graphs/gra001.svg"/>
                                    </span>
                                    <div class="text-inverse-danger fw-bold fs-2 mb-2 mt-5">{{ $t('pages.statistic.student.totalOrder.wrong.title') }}</div>
                                    <div class="row fs-6">
                                        <div class="col-xl-6 d-flex align-items-center">
                                            <div class="text-inverse-danger fw-bold">{{ $t('pages.statistic.student.totalOrder.cols.count') }}:</div>
                                            <div class="text-inverse-danger">{{ user.statistic.total_order.wrong_count }}</div>
                                        </div>
                                        <div class="col-xl-6 d-flex align-items-center">
                                            <div class="text-inverse-danger fw-bold">{{ $t('pages.statistic.student.totalOrder.cols.price') }}:</div>
                                            <div class="text-inverse-danger">{{ user.statistic.total_order.wrong + ' ' + $root.defaultCurrency.suffix }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row g-5 g-xl-8 mb-5 mb-xl-1" v-if="user.statistic.last_read_paragraph || user.statistic.last_exam_result || user.statistic.last_watch_lesson">
                        <div class="col-xl-4" v-if="user.statistic.last_read_paragraph">
                            <div class="card-xl-stretch mb-xl-8 bg-info card">
                                <div class="card-header pt-5 border-bottom-0">
                                    <div class="card-title d-flex flex-column">
                                        <span class="fs-2hx fw-bold text-white me-2 lh-1 ls-n2">{{ user.statistic.last_read_paragraph.title }}</span>
                                        <span class="text-white  pt-1 fw-semibold fs-6">{{ $t('pages.statistic.student.lastReadParagraph.title') }}</span>
                                    </div>
                                </div>
                                <div class="card-body d-flex align-items-end">
                                    <div class="w-100">
                                        <div class="row fs-6">
                                            <div class="col-sm-6 mb-1 mb-sm-0">
                                                <div class="text-inverse-danger fw-bold">{{ $t('pages.statistic.student.lastReadParagraph.cols.readingTime') }}</div>
                                                <div class="text-inverse-danger">{{ user.statistic.last_read_paragraph.reading_time }}</div>
                                            </div>
                                            <div class="col-sm-6 text-start text-sm-end">
                                                <div class="text-inverse-danger fw-bold">{{ $t('pages.statistic.student.lastReadParagraph.cols.spentTime') }}</div>
                                                <div class="text-inverse-danger">{{ user.statistic.last_read_paragraph.spent_time }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4" v-if="user.statistic.last_exam_result">
                            <div class="card-xl-stretch mb-xl-8 bg-primary card">
                                <div class="card-header pt-5 border-bottom-0">
                                    <div class="card-title d-flex flex-column">
                                        <span class="fs-2hx fw-bold text-white me-2 lh-1 ls-n2">{{ user.statistic.last_exam_result.title }}</span>
                                        <span class="text-white  pt-1 fw-semibold fs-6">{{ $t('pages.statistic.student.lastExamResult.title') }}</span>
                                    </div>
                                </div>
                                <div class="card-body d-flex align-items-end">
                                    <div class="w-100">
                                        <div class="row fs-6">
                                            <div class="col-sm-4 mb-1 mb-sm-0">
                                                <div class="text-inverse-danger fw-bold">{{ $t('pages.statistic.student.lastExamResult.cols.grade') }}</div>
                                                <div class="text-inverse-danger">{{ user.statistic.last_exam_result.grade }}</div>
                                            </div>
                                            <div class="col-sm-4 mb-1 mb-sm-0 text-start text-sm-center">
                                                <div class="text-inverse-danger fw-bold">{{ $t('pages.statistic.student.lastExamResult.cols.startDate') }}</div>
                                                <div class="text-inverse-danger">{{ user.statistic.last_exam_result.start_date }}</div>
                                            </div>
                                            <div class="col-sm-4 text-start text-sm-end">
                                                <div class="text-inverse-danger fw-bold">{{ $t('pages.statistic.student.lastExamResult.cols.endDate') }}</div>
                                                <div class="text-inverse-danger">{{ user.statistic.last_exam_result.ending_date }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4" v-if="user.statistic.last_watch_lesson">
                            <div class="card-xl-stretch mb-xl-8 bg-danger card">
                                <div class="card-header pt-5 border-bottom-0">
                                    <div class="card-title d-flex flex-column">
                                        <span class="fs-2hx fw-bold text-white me-2 lh-1 ls-n2">{{ user.statistic.last_watch_lesson.title }}</span>
                                        <span class="text-white  pt-1 fw-semibold fs-6">{{ $t('pages.statistic.student.lastWatchLesson.title') }}</span>
                                    </div>
                                </div>
                                <div class="card-body d-flex align-items-end pt-0 flex-wrap">
                                    <div class="w-100">
                                        <div class="row fs-8 mt-4">
                                            <div class="col-sm-6 mb-1 mb-sm-0">
                                                <div class="text-inverse-danger fw-bolder me-2">{{ $t('pages.statistic.student.lastWatchLesson.cols.lectureTitle') }}</div>
                                                <div class="text-inverse-danger">{{ user.statistic.last_watch_lesson.lecture_title }}</div>
                                            </div>
                                            <div class="col-sm-6 text-start text-sm-end">
                                                <div class="text-inverse-danger fw-bolder">{{ $t('pages.statistic.student.lastWatchLesson.cols.firstWatchingStartDate') }}</div>
                                                <div class="text-inverse-danger">{{ user.statistic.last_watch_lesson.first_watching_start_date }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center flex-column mt-3 w-100">
                                        <div class="d-flex justify-content-between fw-bolder fs-8 text-white w-100 mt-auto mb-2">
                                            <span>{{ sprintf('%s: %s/%s', [$t('pages.statistic.student.lastWatchLesson.cols.watchingDuration'), user.statistic.last_watch_lesson.watching_duration, user.statistic.last_watch_lesson.duration ])}}</span>
                                            <span>{{ user.statistic.last_watch_lesson.watching_percentage }}%</span>
                                        </div>
                                        <div class="h-8px mx-3 w-100 bg-white bg-opacity-50 rounded">
                                            <div class="bg-white rounded h-8px" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" :style="sprintf('width: %d%;', [user.statistic.last_watch_lesson.watching_percentage])"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row g-5 g-xl-8 mb-5 mb-xl-1" v-if="user.statistic.exam_min_max_grade && user.statistic.exam_min_max_grade.min && user.statistic.exam_min_max_grade.max">
                        <div class="col-xl-6" v-if="user.statistic.exam_min_max_grade.min">
                            <div class="card-xl-stretch mb-xl-8 bg-danger card">
                                <div class="card-body">
                                    <span class="svg-icon svg-icon-white svg-icon-3x">
                                        <inline-svg src="/media/icons/duotune/graphs/gra001.svg"/>
                                    </span>
                                    <div class="text-inverse-danger fw-bold fs-2 mb-2 mt-5">{{ $t('pages.statistic.student.examMinMaxGrade.min.title')}}</div>
                                    <div class="row fs-6">
                                        <div class="col-sm-3 mb-1 mb-sm-0">
                                            <div class="text-inverse-danger fw-bold">{{ $t('pages.statistic.student.examMinMaxGrade.cols.title') }}</div>
                                            <div class="text-inverse-danger text-truncate">{{ user.statistic.exam_min_max_grade.min.title }}</div>
                                        </div>
                                        <div class="col-sm-3 mb-1 mb-sm-0 text-start text-sm-center">
                                            <div class="text-inverse-danger fw-bold">{{ $t('pages.statistic.student.examMinMaxGrade.cols.grade') }}</div>
                                            <div class="text-inverse-danger">{{ user.statistic.exam_min_max_grade.min.grade }}</div>
                                        </div>
                                        <div class="col-sm-3 mb-1 mb-sm-0 text-start text-sm-center">
                                            <div class="text-inverse-danger fw-bold">{{ $t('pages.statistic.student.examMinMaxGrade.cols.startDate') }}</div>
                                            <div class="text-inverse-danger">{{ user.statistic.exam_min_max_grade.min.start_date }}</div>
                                        </div>
                                        <div class="col-sm-3 text-start text-sm-end">
                                            <div class="text-inverse-danger fw-bold">{{ $t('pages.statistic.student.examMinMaxGrade.cols.endDate') }}</div>
                                            <div class="text-inverse-danger">{{ user.statistic.exam_min_max_grade.min.ending_date }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6" v-if="user.statistic.exam_min_max_grade.max">
                            <div class="card-xl-stretch mb-xl-8 bg-info card">
                                <div class="card-body">
                                    <span class="svg-icon svg-icon-white svg-icon-3x">
                                        <inline-svg src="/media/icons/duotune/graphs/gra001.svg"/>
                                    </span>
                                    <div class="text-inverse-danger fw-bold fs-2 mb-2 mt-5">{{ $t('pages.statistic.student.examMinMaxGrade.max.title')}}</div>
                                    <div class="row fs-6">
                                        <div class="col-sm-3 mb-1 mb-sm-0">
                                            <div class="text-inverse-danger fw-bold">{{ $t('pages.statistic.student.examMinMaxGrade.cols.title') }}</div>
                                            <div class="text-inverse-danger text-truncate">{{ user.statistic.exam_min_max_grade.max.title }}</div>
                                        </div>
                                        <div class="col-sm-3 mb-1 mb-sm-0 text-start text-sm-center">
                                            <div class="text-inverse-danger fw-bold">{{ $t('pages.statistic.student.examMinMaxGrade.cols.grade') }}</div>
                                            <div class="text-inverse-danger">{{ user.statistic.exam_min_max_grade.max.grade }}</div>
                                        </div>
                                        <div class="col-sm-3 mb-1 mb-sm-0 text-start text-sm-center">
                                            <div class="text-inverse-danger fw-bold">{{ $t('pages.statistic.student.examMinMaxGrade.cols.startDate') }}</div>
                                            <div class="text-inverse-danger">{{ user.statistic.exam_min_max_grade.max.start_date }}</div>
                                        </div>
                                        <div class="col-sm-3 text-start text-sm-end">
                                            <div class="text-inverse-danger fw-bold">{{ $t('pages.statistic.student.examMinMaxGrade.cols.endDate') }}</div>
                                            <div class="text-inverse-danger">{{ user.statistic.exam_min_max_grade.max.ending_date }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row g-5 g-xl-8 mb-5 mb-xl-1" v-if="user.statistic.last_login">
                        <div class="col-sm-12">
                            <custom-table
                                :title="$t('pages.statistic.student.lastLogin.title')"
                                :subTitle="$t('pages.statistic.student.lastLogin.subTitle')"
                                :items="user.statistic.last_login"
                                :columns="lastLogin.fields">
                                <template v-slot:created_at="{ row: record }">
                                    {{ $moment(record.created_at).format("YYYY-MM-DD HH:mm") }}
                                </template>
                            </custom-table>
                        </div>
                    </div>
                </div>
                <div v-else class="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
                    <span class="svg-icon svg-icon-2tx svg-icon-warning me-4">
                        <inline-svg src="/media/icons/duotune/general/gen044.svg" />
                    </span>
                    <div class="d-flex flex-stack flex-grow-1">
                        <div class="fw-bold">
                            <h4 class="text-gray-900 fw-bold">{{ $t('pages.statistic.student.warning.notFoundStatistic.title')}}</h4>
                            <div class="fs-6 text-gray-600">{{ $t('pages.statistic.student.warning.notFoundStatistic.description')}}</div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";
export default {
    name: "index",
    props: {
        users: {type: Array, default: () => { return []}},
        showUserTitle: {type: Boolean, default: true},
    },
    components: {
        CustomTable
    },
    data(){
        return {
            lastLogin: {
                fields: [
                    {
                        name: this.$t("common.ipAddress"),
                        key: "ip"
                    },
                    {
                        name: this.$t("common.userAgent"),
                        key: "user_agent"
                    },
                    {
                        name: this.$t("pages.statistic.student.lastLogin.cols.createdAt"),
                        scopedSlots: {customRender: "created_at"},
                    },
                ],
            },
            userStatistics: [],
            showUserID: undefined,
            loading: false
        }
    },
    created(){
        this.loadStatistic();
    },
    methods: {
        loadStatistic(){
            if(!this.users ||!Array.isArray(this.users) || !this.users.length){
                return;
            }

            this.setLoading(true);

            this.axios.post(this.endpoints['statistic_student'], {users: this.users}).then(response => {
                this.onResponse(response.data, () => {
                    let data = response.data.data;
                    this.userStatistics = data.map((user) => {
                        let status = false;
                        for (let statisticKey in user.statistic){
                            let statistic = user.statistic[statisticKey];
                            if(statistic) {
                                if(typeof(statistic) == 'object') {
                                    if(Array.isArray(statistic)) {
                                        status = statistic.length > 1;
                                    } else {
                                        for(let key in statistic) {
                                            let data = statistic[key];
                                            if(data) {
                                                status = true;
                                            }
                                        }
                                    }
                                } else {
                                    status = true;
                                }
                            }
                        }

                        user.is_statistic_available = status;

                        return user;
                    });

                    if(data.length){
                        this.showUserID = data[0].id;
                    }
                }, undefined, false);

            }).catch(error => {
                this.onResponseFailure(error.response.data);
            }).finally(() => {
                this.setLoading(false);
            });
        },
        showUser(userID){
            this.showUserID = this.showUserID != userID ? userID : undefined;
        },
        setLoading(status) {
            this.$emit('loading', status);
            this.loading = status;
        }
    }
}
</script>

<style scoped>

</style>